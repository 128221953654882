import {AnalyticsPageTypes} from '@/app.model';
import {DeviceType} from "@/shared/utils/userAgentContext";
import {polyfillRequestIdleCallback} from "@/shared/utils/polyfills";
import {CookieService} from "@arkadium/modules/dist/lib/_shared/services/CookieService";
import {LS_COOKIE_CONSTS} from "@arkadium/modules/dist/lib/Analytics/models/Enums";

export const initAppInsight = async (isForServer = false) => {
    return await new Promise<void>((resolve) => {
        polyfillRequestIdleCallback(async () => {
            const importedModule = await import('@arkadium/modules/dist/lib/_shared/services/AppInsightService');
            await importedModule.AppInsightService.init(
                !isForServer
                    ? (process.env.APP_INSIGHTS_INSTRUMENTATION_KEY_FRONT as string)
                    : (process.env.APP_INSIGHTS_INSTRUMENTATION_KEY as string),
                window.location.href,
                {},
                window.innerWidth + 'x' + window.innerHeight
            );
            resolve();
        });
    });
};

export type BaseParams = {
    pageType: AnalyticsPageTypes;
    fpVisitorId: string;
    fpIncognito: boolean;
    isMobile: boolean;
    loggedIn: boolean;
    arenaVersion: string;
    buildVersion: string;
    domain: string;
    screenResolution: string;
    targetConcent: boolean | null;
    gdprConsent: boolean | null;
    userType?: UserTypes;
};

export type BaseCustomDimensions = {
    arenaID: string;
    arenaLayoutID: string;
    modularPageID: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
};

export type AiData = {
    baseParams: BaseParams;
    baseCustomDimensions: BaseCustomDimensions;
};

enum UserTypes {
    NEW = 'new',
    RETURNING = 'returning',
}
export const handleUserType = (): UserTypes => {
    // IF SAVED AS RETURNING, THEN RETURN RETURNING
    const savedUserType = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN) || 'new';
    if (savedUserType === 'returning') return UserTypes.RETURNING; // we already know that the user is returning one
    // IF NOT SAVED AS RETURNING, THEN RE-CHECK
    let userType: UserTypes  = UserTypes.NEW;
    const gaCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.GA);
    const d = new Date();
    d.setTime(d.getTime() + 1825 * 24 * 60 * 60 * 1000);
    if (typeof gaCookie !== 'boolean' && gaCookie.length > 0) {
        const cookieValues = gaCookie.split('.');
        const currentDate = new Date();
        const sec = (currentDate.getTime() - parseInt(cookieValues[3], 10) * 1000) / 1000;
        if (sec >= 60 * 60) {
            userType = UserTypes.RETURNING;
        }
    }
    CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN, userType); // save the user type for future quick use
    return userType;
}

export const baseParams: any = (
    pageType?: AnalyticsPageTypes,
    fpVisitorId?: string,
    fpIncognito?: boolean,
    deviceType?: DeviceType,
    loggedIn?: boolean,
    gdprConsent?: boolean | null
): BaseParams => {
    return {
        pageType,
        deviceType,
        fpVisitorId: fpVisitorId,
        fpIncognito: fpIncognito,
        loggedIn: loggedIn ?? false,
        arenaVersion: 'arena-evolution',
        isMobile: deviceType !== DeviceType.DESKTOP,
        buildVersion: process.env.buildid ?? 'dev',
        domain: typeof window !== 'undefined' ? window?.location?.host : '',
        screenResolution: typeof window !== 'undefined' ? window.innerWidth + 'x' + window.innerHeight : '0x0',
        gdprConsent,
        userType: handleUserType(),
    } as unknown as BaseParams;
};
