import { ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { createEvent, createStore, sample } from 'effector';
import { GameState } from '@/features/games/model';
import { logger } from '@/shared/utils/logger';

export enum PrerollType {
    PREROLL = 'pre-roll',
    REWARD = 'reward',
    INTERSTITIAL = 'interstitial',
}

type CTAScreenConfig = {
    containerClass: string;
    colRightClass: string;
    messageBoxClass: string;
    buttonClass: string;
    buttonTextClass: string;
    messageText: string;
};

export const $prerollStartTime = createStore(0);
export const startPreroll = createEvent();
export const $prerollEndTime = createStore(0);
export const endPreroll = createEvent();
sample({
    clock: startPreroll,
    fn: () => performance.now(),
    target: $prerollStartTime,
});

sample({
    clock: endPreroll,
    fn: () => performance.now(),
    target: $prerollEndTime,
});

sample({
    clock: $prerollEndTime,
    source: $prerollStartTime,
    fn: (startTime: number, endTime: number) => {
        if (startTime > 0 && endTime > 0) {
            logger.debug('preroll lasts for ~' + (endTime - startTime) / 1000 + 's');
        }
        return endTime - startTime;
    },
});
const INIT_PREROLL_MESSAGE = 'Start playing after the ad';
const INIT_CTA_BUTTON_TEXT = 'Play';
export const $prerollMessageText = createStore<string>(INIT_PREROLL_MESSAGE);
export const prerollTextUpdated = createEvent<{ gameState: GameState; configGamePage: ConfigGamePage | null }>();
export const $prerollCtaButtonText = createStore<string>(INIT_CTA_BUTTON_TEXT);

export const CTA_MESSAGE_MAX_LENGTH = 104;
sample({
    clock: prerollTextUpdated,
    fn: ({ gameState, configGamePage }) => {
        if (gameState === GameState.PREROLL) {
            return configGamePage?.gameStartConfig?.message?.slice?.(0, CTA_MESSAGE_MAX_LENGTH) ?? INIT_PREROLL_MESSAGE;
        }
        return INIT_PREROLL_MESSAGE;
    },
    target: $prerollMessageText,
});

sample({
    clock: prerollTextUpdated,
    fn: ({ gameState, configGamePage }) => {
        if (gameState === GameState.PREROLL) {
            return configGamePage?.gameStartConfig?.buttonText?.slice(0, 16) ?? INIT_CTA_BUTTON_TEXT;
        }
        return INIT_CTA_BUTTON_TEXT;
    },
    target: $prerollCtaButtonText,
});
