import React, { cache } from 'react';
import { ArenaDataState } from '@/shared/api/arena-data';
import { generateUiKitUrlUsed } from '@/shared/api/data-fetcher';

export const UIKIT_CONSTS: {
    urlBase: string;
    urlVersionDefault: string;
    urlBundleFileName: string;
    urlStylesGlobalFileName: string;
    urlStylesGlobalFileClass: string;
    urlSsrFileName: string;
    nextjsLinkWrapperClassname: string;
} = {
    urlBase: 'https://arenacloud.cdn.arkadiumhosted.com/arkadiummodulesstorage-blob/arkadium-ui-kit',
    urlVersionDefault: 'nightly',
    urlBundleFileName: 'bundle.min.js',
    urlStylesGlobalFileName: 'global_styles_ark-ui.css',
    urlStylesGlobalFileClass: 'ark-ui_StylesGlobal',
    urlSsrFileName: 'bundle.min.js',
    nextjsLinkWrapperClassname: 'ark-ui-nextjs-link',
};

export type TUikitConnectorProps = {
    arenaData: ArenaDataState;
};

export const USE_SSR_ONLY: boolean = true; // dev mode with webcomponents on == false + ?__uikit-devmode

export const UiKitConnector = async ({ arenaData }: TUikitConnectorProps): Promise<any> => {
    const version = arenaData?.layout?.themeLayout?.webKitVersion || UIKIT_CONSTS.urlVersionDefault;
    const stylesUrl = await generateUiKitUrlUsed(version, UIKIT_CONSTS.urlStylesGlobalFileName);

    return (
        <>
            {await GeneratedPlatformStyles(arenaData)}
            <link // global styles
                rel="stylesheet"
                href={stylesUrl}
                className={UIKIT_CONSTS.urlStylesGlobalFileClass}
            />
        </>
    );
};

async function GeneratedPlatformStyles(arenaData: ArenaDataState | null) {
    if (!arenaData) return null;
    const { styleOverride, themeLayout } = arenaData?.layout ?? {};
    const styleFontFamily = themeLayout?.fontFamily || 'Readex Pro';
    const styleLayout = themeLayout?.layout || 'wide';
    const styleArenaMaxWidth = themeLayout?.arenaMaxWidth || null;
    const faviconUrl = arenaData?.favIcon?.url + `?v=` + Math.random() || ''; // To force favicon refresh
    const cssFontFamilyUrl = `https://fonts.googleapis.com/css?family=${styleFontFamily
        .split(' ')
        .join('+')}&display=swap`;
    const cssStyleOverrideStr: string =
        '' +
        // BASIC CSS VARS
        `:root {
            --top-ad-height: 74px;
        }` +
        // CMS FONT FAMILY
        `:root {
            --font-family-main: "${styleFontFamily}", sans-serif;
        }` +
        // CMS LAYOUT && ARENA MAX WIDTH
        (styleLayout !== 'centered' || !styleArenaMaxWidth
            ? ''
            : `body {
            max-width: ${styleArenaMaxWidth}px;
            margin: auto;
        }`) +
        // CMS STYLE OVERRIDES
        (!styleOverride
            ? ''
            : `:root {
            ${generateCardCssVarsFromStyleOverride(styleOverride)}
        }`);

    return (
        <>
            <link rel="icon" type="image/x-icon" href={faviconUrl}></link>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link rel="stylesheet" href={cssFontFamilyUrl} />
            <style className={'ark-ui_PlatformVariables'} dangerouslySetInnerHTML={{ __html: cssStyleOverrideStr }} />
        </>
    );
}

export const UiKitGrid = {
    gridClass: 'ark-ui-grid',
    rowClass: 'row',
    rowWideClass: 'row __fullwidth',
};

function generateCardCssVarsFromStyleOverride(styleOverride: any) {
    const cardsCssVarsBlocksArr = Object.keys(styleOverride || {}).map((key) => {
        const cardName = key; // e.g. "bigHeroCard"
        const cardOverridesObj = styleOverride[key]; // e.g. styleOverride.bigHeroCard
        const cardCssVarsStr = generateCssVarsBlockStringFromObj(cardOverridesObj, `${cardName}-override-color`);
        const cardCssVarsBlock = `\n\n/* ${cardName} - OVERRIDE CSS VARS */\n` + cardCssVarsStr;
        return cardCssVarsBlock;
    });
    const cardsCssVarsBlocksJoined = cardsCssVarsBlocksArr.join('');
    return cardsCssVarsBlocksJoined;
}

function generateCssVarsBlockStringFromObj(obj: any, prefix: string = ''): string {
    return Object.keys(obj || {})
        .map((key) => {
            const propKey = key;
            const propValue = obj?.[key];
            if (!propValue) return '';
            if (typeof propValue === 'object' && !Array.isArray(propValue)) {
                return generateCssVarsBlockStringFromObj(propValue, propKey);
            } else if (typeof propValue !== 'string' && Array.isArray(propValue)) {
                let result = '';
                try {
                    const subprefix = (el: any) => `${prefix}-${propKey}${el?.state ? '-' + el.state : ''}`;
                    result = propValue
                        .map((el, i) => {
                            return generateCssVarsBlockStringFromObj(el, subprefix(el));
                        })
                        .join('\n');
                } catch (e) {
                    result = `/* GENERATION ERROR: ${e} */`;
                }
                return result;
            }
            return `\n--${prefix ? prefix + '-' : ''}${propKey}: ${propValue} !important;`;
        })
        .join('');
}
